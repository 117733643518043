import { Box, Container, Grid, Typography, useMediaQuery } from "@mui/material";
import React from "react";

import {
  walletIcon,
  amountIcon,
  confirmIcon,
} from "../components/SmallComponents/Images";

const HowToBuy = () => {
  const mobileMatches = useMediaQuery("(max-width:650px)");
  const stepsData = [
    {
      title: "Step 1",
      description: (
        <span>
          Start by ensuring that you have a MetaMask wallet installed on your
          browser. Alternatively, you can opt for one of the supported wallets
          through Wallet Connect.
          <br />
          <br /> For optimal purchasing experience, it's advisable to make
          purchases via a desktop browser. In such cases, we suggest utilizing
          MetaMask.
        </span>
      ),
      logo: walletIcon,
    },
    {
      title: "Step 2",
      description: (
        <span>
          Once you've chosen your preferred wallet provider, click on "Connect
          Wallet" and choose the relevant option. If you're using a mobile
          wallet app, be sure to select "Wallet Connect".
          <br /> Choose the payment option (ETH, USDT, USDC) or click on Credit
          Card button to use your credit card.
        </span>
      ),
      logo: amountIcon,
    },
    {
      title: "Step 3",
      description:
        "After the presale ends and the Claim button becomes accessible, just click on it using the connected wallet through which you purchased the tokens. Then, simply claim your POLI tokens. That's all there is to it!",
      logo: confirmIcon,
    },
  ];
  return (
    <Box pb={10} id="how-to-buy">
      <Box
        sx={{
          display: "flex",
          justifyContent: "center",
        }}
      >
        <Box mb={6}>
          <Typography
            mt={{ xs: 1, sm: 3 }}
            // variant="h5"
            sx={{
              fontFamily: "Outfit",
              fontSize: { xs: "28px", md: "48px" },
              fontWeight: "800",
              lineHeight: "48px",
              color: "#ffffff",
            }}
          >
            HOW TO BUY
          </Typography>
          <Typography
            variant="body2"
            pb={2}
            sx={{
              fontFamily: "Outfit",
              fontSize: "17px",
              fontWeight: "400",
              lineHeight: "20px",
              textAlign: "center",
              color: "#ffffff",
            }}
          >
            Buy POLI in 3 steps
          </Typography>
        </Box>
      </Box>
      <Container maxWidth="xl">
        <Grid
          container
          spacing={{ xs: 5, md: 2 }}
          sx={{
            display: "flex",
            justifyContent: "center",
            color: "#ffffff",
          }}
        >
          {stepsData.map(({ title, description, logo }, index) => (
            <Grid
              item
              xs={12}
              md={4}
              pt={10}
              key={index}
              sx={{
                display: "flex",
                justifyContent: "center",
              }}
            >
              <Box
                data-aos={
                  index === 0
                    ? mobileMatches
                      ? "fade-down"
                      : "fade-right"
                    : index === 1
                    ? "fade-up"
                    : mobileMatches
                    ? "fade-up"
                    : "fade-left"
                }
                data-aos-delay="30"
                data-aos-duration="2000"
                sx={{
                  height: "100%",
                  color: "#ffffff",
                  textAlign: "center",
                  bgcolor: "#1f517d80",
                  border: "2px solid #C81724",
                  borderRadius: "12px",
                  pb: 2,
                }}
              >
                <Box
                  component="img"
                  height={52}
                  width={52}
                  src={logo}
                  sx={{
                    position: "relative",
                    top: "-10%",
                  }}
                />

                <Typography
                  px={5}
                  // variant="h5"
                  sx={{
                    fontFamily: "Outfit",
                    fontSize: "22px",
                    fontWeight: "400",
                    lineHeight: "21px",
                  }}
                >
                  {title}
                </Typography>
                <Typography
                  variant="body2"
                  pt={1}
                  px={5}
                  sx={{
                    fontFamily: "Outfit",
                    fontSize: "15px",
                    fontWeight: "500",
                    lineHeight: "20px",
                  }}
                >
                  {description}
                </Typography>
              </Box>
            </Grid>
          ))}
        </Grid>
      </Container>
    </Box>
  );
};

export default HowToBuy;

import React from "react";
import PresalePage from "./HeroSection";
import HowToBuy from "./HowToBuy";

function MainWebsite() {
  return (
    <div className="body_wrap">
      <div className="page_wrap">
        <div className="page_content_wrap">
          <div className="content_wrap">
            <div className="content">
              <article
                id="post-464"
                className="post_item_single post_type_page post-464 page type-page status-publish has-post-thumbnail hentry"
              >
                <div className="post_content entry-content">
                  <div
                    data-elementor-type="wp-post"
                    data-elementor-id={464}
                    className="elementor elementor-464"
                  >
                    <section
                      className="elementor-section elementor-top-section elementor-element elementor-element-12bdbac elementor-section-stretched elementor-section-full_width elementor-section-height-default elementor-section-height-default"
                      data-id="12bdbac"
                      data-element_type="section"
                      data-settings='{"stretch_section":"section-stretched"}'
                    >
                      <div className="elementor-container elementor-column-gap-no">
                        <div
                          className="elementor-column elementor-col-100 elementor-top-column elementor-element elementor-element-d123061 sc_layouts_column_icons_position_left"
                          data-id="d123061"
                          data-element_type="column"
                        >
                          <div className="elementor-widget-wrap elementor-element-populated">
                            <div
                              className="elementor-element elementor-element-9d2958f sc_fly_static elementor-widget elementor-widget-shortcode"
                              data-id="9d2958f"
                              data-element_type="widget"
                              data-widget_type="shortcode.default"
                            >
                              <div className="elementor-widget-container">
                                <div className="elementor-shortcode">
                                  {/* START Home Slide REVOLUTION SLIDER 6.6.20 */}
                                  <p className="rs-p-wp-fix" />
                                  <rs-module-wrap
                                    id="rev_slider_1_1_wrapper"
                                    data-source="gallery"
                                    style={{
                                      visibility: "hidden",
                                      background: "#1a273d",
                                      padding: 0,
                                      margin: "0px auto",
                                      marginTop: 0,
                                      marginBottom: 0,
                                    }}
                                  >
                                    <rs-module
                                      id="rev_slider_1_1"
                                      style={{}}
                                      data-version="6.6.20"
                                    >
                                      <rs-slides
                                        style={{
                                          overflow: "hidden",
                                          position: "absolute",
                                        }}
                                      >
                                        <rs-slide
                                          style={{ position: "absolute" }}
                                          data-key="rs-2"
                                          data-title="Slide"
                                          data-thumb="/wp-content/uploads/2024/04/website-banner-Politicoin-100x50.jpg"
                                          data-anim="p:transparent;"
                                          data-in="o:0;"
                                          data-out="o:0;"
                                        >
                                          <img
                                            fetchpriority="high"
                                            decoding="async"
                                            src="/wp-content/plugins/revslider/public/assets/assets/dummy.png"
                                            alt=""
                                            title="website banner Politicoin"
                                            width={1024}
                                            height={1024}
                                            className="rev-slidebg tp-rs-img rs-lazyload"
                                            data-lazyload="/wp-content/uploads/2024/04/website-banner-Politicoin.jpg"
                                            data-bg="p:0% 20%;"
                                            data-no-retina=""
                                          />
                                          <rs-layer
                                            id="slider-1-slide-2-layer-1"
                                            data-type="text"
                                            data-rsp_ch="on"
                                            data-xy="x:c;xo:30px,30px,0,0;yo:185px,185px,184px,255px;"
                                            data-text="w:normal;s:90,90,70,40;l:100,100,80,50;fw:700;a:center;"
                                            data-dim="w:992px,992px,746px,399px;h:auto,auto,162px,auto;"
                                            data-frame_1="sp:480;sR:10;"
                                            data-frame_999="o:0;e:power2.in;st:w;sp:1000;sR:8510;"
                                            style={{
                                              zIndex: 6,
                                              fontFamily: '"Butler"',
                                            }}
                                          ></rs-layer>

                                          <rs-layer
                                            id="slider-1-slide-2-layer-2"
                                            data-type="text"
                                            data-rsp_ch="on"
                                            data-xy="x:c;yo:405px;"
                                            data-text="w:normal;s:30;l:40;a:center;"
                                            data-dim="w:243px;h:41px;"
                                            data-frame_1="sp:480;sR:10;"
                                            data-frame_999="o:0;st:w;sp:1000;sR:8510;"
                                            style={{
                                              zIndex: 7,
                                              fontFamily: '"Arimo"',
                                            }}
                                          ></rs-layer>
                                          {/*
                                           */}
                                        </rs-slide>
                                        <rs-slide
                                          style={{ position: "absolute" }}
                                          data-key="rs-1"
                                          data-title="Slide"
                                          data-thumb="/wp-content/uploads/2024/04/Website-banner-1-100x50.jpg"
                                          data-anim="p:transparent;"
                                          data-in="o:0;"
                                          data-out="o:0;"
                                        >
                                          <img
                                            decoding="async"
                                            src="/wp-content/plugins/revslider/public/assets/assets/dummy.png"
                                            alt=""
                                            title="Website banner 1"
                                            width={1024}
                                            height={1024}
                                            className="rev-slidebg tp-rs-img rs-lazyload"
                                            data-lazyload="/wp-content/uploads/2024/04/Website-banner-1.jpg"
                                            data-bg="p:0% 10%;"
                                            data-no-retina=""
                                          />
                                          {/*
                                           */}
                                          <rs-layer
                                            id="slider-1-slide-1-layer-1"
                                            data-type="text"
                                            data-rsp_ch="on"
                                            data-xy="x:c;xo:30px,30px,0,0;yo:185px,185px,184px,255px;"
                                            data-text="w:normal;s:90,90,70,40;l:100,100,80,50;fw:700;a:center;"
                                            data-dim="w:992px,992px,746px,399px;h:auto,auto,162px,auto;"
                                            data-frame_1="sp:480;sR:10;"
                                            data-frame_999="o:0;e:power2.in;st:w;sp:1000;sR:8510;"
                                            style={{
                                              zIndex: 5,
                                              fontFamily: '"Butler"',
                                            }}
                                          >
                                            Uniting Humor and Accountability –
                                            One Meme at a Time{" "}
                                          </rs-layer>
                                          {/*
                                           */}
                                        </rs-slide>
                                      </rs-slides>
                                    </rs-module>
                                  </rs-module-wrap>
                                  {/* END REVOLUTION SLIDER */}
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </section>
                    <section
                      className="elementor-section elementor-top-section elementor-element elementor-element-8cb75e1 elementor-section-stretched elementor-section-full_width elementor-section-height-default elementor-section-height-default elementor-invisible"
                      data-id="8cb75e1"
                      data-element_type="section"
                      data-settings='{"stretch_section":"section-stretched","background_background":"classic","animation":"fadeIn"}'
                    >
                      <div className="elementor-container elementor-column-gap-extended">
                        <div
                          className="elementor-column elementor-col-100 elementor-top-column elementor-element elementor-element-138bd47 sc_layouts_column_icons_position_left"
                          data-id="138bd47"
                          data-element_type="column"
                        >
                          <div className="elementor-widget-wrap elementor-element-populated">
                            <div
                              className="elementor-element elementor-element-559791b sc_fly_static elementor-widget elementor-widget-spacer"
                              data-id="559791b"
                              data-element_type="widget"
                              data-widget_type="spacer.default"
                            >
                              <div className="elementor-widget-container">
                                <div className="elementor-spacer">
                                  <div className="elementor-spacer-inner" />
                                </div>
                              </div>
                            </div>
                            <div
                              className="elementor-element elementor-element-dcf11cb sc_fly_static elementor-widget elementor-widget-image"
                              data-id="dcf11cb"
                              data-element_type="widget"
                              data-widget_type="image.default"
                            >
                              <div className="elementor-widget-container">
                                <img
                                  decoding="async"
                                  width={250}
                                  height={95}
                                  src="wp-content/uploads/2024/04/POLITICOIN-4-6-20244.png"
                                  className="attachment-full size-full wp-image-3268"
                                  alt=""
                                />
                              </div>
                            </div>
                            <div
                              className="elementor-element elementor-element-a4c2fa9 sc_fly_static elementor-widget elementor-widget-spacer"
                              data-id="a4c2fa9"
                              data-element_type="widget"
                              data-widget_type="spacer.default"
                            >
                              <div className="elementor-widget-container">
                                <div className="elementor-spacer">
                                  <div className="elementor-spacer-inner" />
                                </div>
                              </div>
                            </div>
                            <div
                              className="elementor-element elementor-element-c148f50 scheme_dark sc_fly_static elementor-widget elementor-widget-trx_sc_title"
                              data-id="c148f50"
                              data-element_type="widget"
                              data-widget_type="trx_sc_title.default"
                            >
                              <div className="elementor-widget-container">
                                <div
                                  id="trx_sc_title_140211696"
                                  className="sc_title color_style_dark scheme_dark sc_title_default"
                                >
                                  <h4 className="sc_item_title sc_title_title sc_align_center sc_item_title_style_default sc_item_title_tag">
                                    <span className="sc_item_title_text">
                                      <i>Contract</i>
                                      0xb125b35b46e8189b73455a9df776165efb7bb76f
                                    </span>
                                  </h4>
                                </div>
                                {/* /.sc_title */}
                              </div>
                            </div>
                            <section
                              className="elementor-section elementor-inner-section elementor-element elementor-element-9d18e0b elementor-section-content-top sc_layouts_hide_on_mobile elementor-section-boxed elementor-section-height-default elementor-section-height-default"
                              data-id="9d18e0b"
                              data-element_type="section"
                            >
                              <div className="elementor-container elementor-column-gap-extended">
                                <div
                                  className="elementor-column elementor-col-100 elementor-inner-column elementor-element elementor-element-78042dd sc_layouts_column sc_layouts_column_align_right sc_layouts_column_icons_position_right"
                                  data-id="78042dd"
                                  data-element_type="column"
                                >
                                  <div className="elementor-widget-wrap elementor-element-populated">
                                    <div
                                      className="elementor-element elementor-element-6342c7c scheme_dark elementor-widget__width-initial sc_fly_static elementor-widget elementor-widget-trx_sc_title"
                                      data-id="6342c7c"
                                      data-element_type="widget"
                                      data-widget_type="trx_sc_title.default"
                                    >
                                      <div className="elementor-widget-container">
                                        <div
                                          id="trx_sc_title_743082633"
                                          className="sc_title color_style_dark scheme_dark sc_title_default"
                                        >
                                          <h6 className="sc_item_title sc_title_title sc_align_right sc_item_title_style_default sc_item_title_tag">
                                            <span className="sc_item_title_text">
                                              <i>Professionally audited by</i>
                                            </span>
                                          </h6>
                                        </div>
                                        {/* /.sc_title */}
                                      </div>
                                    </div>
                                    <div
                                      className="elementor-element elementor-element-d7c3655 elementor-widget__width-initial sc_fly_static elementor-widget elementor-widget-image"
                                      data-id="d7c3655"
                                      data-element_type="widget"
                                      data-widget_type="image.default"
                                    >
                                      <div className="elementor-widget-container">
                                        <a href="./audit.pdf" target="_blank">
                                          <img
                                            loading="lazy"
                                            decoding="async"
                                            width={217}
                                            height={65}
                                            src="wp-content/uploads/2024/05/logo-white.png"
                                            className="attachment-large size-large wp-image-3514"
                                            alt=""
                                          />
                                        </a>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </section>
                            <div
                              className="elementor-element elementor-element-e863ccc sc_fly_static elementor-widget elementor-widget-trx_sc_title"
                              data-id="e863ccc"
                              data-element_type="widget"
                              data-settings='{"_animation":"none"}'
                              data-widget_type="trx_sc_title.default"
                            >
                              <div className="elementor-widget-container">
                                <div
                                  id="trx_sc_title_2030587865"
                                  className="sc_title sc_title_default"
                                >
                                  <h1 className="sc_item_title sc_title_title sc_align_center sc_item_title_style_default sc_item_title_tag trx_addons_inline_922841105">
                                    <span className="sc_item_title_text">
                                      WE TAKE FUN SERIOUSLY!
                                    </span>
                                  </h1>
                                </div>
                                {/* /.sc_title */}
                              </div>
                            </div>
                            <div
                              className="elementor-element elementor-element-a720704 sc_fly_static elementor-widget elementor-widget-spacer"
                              data-id="a720704"
                              data-element_type="widget"
                              data-widget_type="spacer.default"
                            >
                              <div className="elementor-widget-container">
                                <div className="elementor-spacer">
                                  <div className="elementor-spacer-inner" />
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                      <PresalePage />
                      <HowToBuy />
                    </section>
                    <section
                      className="elementor-section elementor-top-section elementor-element elementor-element-8308e6c sc_layouts_hide_on_wide sc_layouts_hide_on_desktop sc_layouts_hide_on_notebook sc_layouts_hide_on_tablet sc_layouts_hide_on_mobile elementor-section-boxed elementor-section-height-default elementor-section-height-default"
                      data-id="8308e6c"
                      data-element_type="section"
                    >
                      <div className="elementor-container elementor-column-gap-extended">
                        <div
                          className="elementor-column elementor-col-100 elementor-top-column elementor-element elementor-element-d32b1ae sc_layouts_column_icons_position_left"
                          data-id="d32b1ae"
                          data-element_type="column"
                        >
                          <div className="elementor-widget-wrap elementor-element-populated">
                            <section
                              className="elementor-section elementor-inner-section elementor-element elementor-element-fbd484d elementor-section-boxed elementor-section-height-default elementor-section-height-default"
                              data-id="fbd484d"
                              data-element_type="section"
                            >
                              <div className="elementor-container elementor-column-gap-no">
                                <div
                                  className="elementor-column elementor-col-50 elementor-inner-column elementor-element elementor-element-44c6e82 sc_layouts_column_icons_position_left"
                                  data-id="44c6e82"
                                  data-element_type="column"
                                  data-settings='{"background_background":"classic","animation":"none"}'
                                >
                                  <div className="elementor-widget-wrap elementor-element-populated">
                                    <div
                                      className="elementor-element elementor-element-00c12aa sc_fly_static elementor-widget elementor-widget-spacer"
                                      data-id="00c12aa"
                                      data-element_type="widget"
                                      data-widget_type="spacer.default"
                                    >
                                      <div className="elementor-widget-container">
                                        <div className="elementor-spacer">
                                          <div className="elementor-spacer-inner" />
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                                <div
                                  className="elementor-column elementor-col-50 elementor-inner-column elementor-element elementor-element-e572dac sc_layouts_column_icons_position_left"
                                  data-id="e572dac"
                                  data-element_type="column"
                                  data-settings='{"background_background":"classic","animation":"none"}'
                                >
                                  <div className="elementor-widget-wrap elementor-element-populated">
                                    <div
                                      className="elementor-element elementor-element-feb513a sc_fly_static elementor-widget elementor-widget-trx_sc_title"
                                      data-id="feb513a"
                                      data-element_type="widget"
                                      data-widget_type="trx_sc_title.default"
                                    >
                                      <div className="elementor-widget-container">
                                        <div
                                          id="trx_sc_title_153661737"
                                          className="sc_title color_style_dark sc_title_default"
                                        >
                                          <h6 className="sc_item_title sc_title_title sc_item_title_style_default sc_item_title_tag trx_addons_inline_996652774">
                                            <span className="sc_item_title_text">
                                              POLITICOIN serves as a stark
                                              reminder to all parties in
                                              Washington: every decision made
                                              carries weight, as it will be
                                              immortalized in meme form. With
                                              every misstep or choice that harms
                                              the people, our collective voice
                                              grows louder, holding politicians
                                              accountable for their actions.
                                              Together, we're forging a path
                                              towards a more transparent and
                                              responsible political landscape.
                                            </span>
                                          </h6>
                                        </div>
                                        {/* /.sc_title */}
                                      </div>
                                    </div>
                                    <div
                                      className="elementor-element elementor-element-8dd9bfe sc_fly_static elementor-widget elementor-widget-trx_sc_button"
                                      data-id="8dd9bfe"
                                      data-element_type="widget"
                                      data-widget_type="trx_sc_button.default"
                                    >
                                      <div className="elementor-widget-container">
                                        <div className="sc_item_button sc_button_wrap sc_align_center">
                                          <a
                                            href="https://t.me/Politi_Coin"
                                            id="trx_sc_button_198698945"
                                            className="sc_button color_style_dark sc_button_video sc_button_size_small sc_button_icon_top"
                                            target="_blank"
                                          >
                                            <span className="sc_button_text sc_align_center">
                                              <span className="sc_button_subtitle">
                                                POLITICOIN: $POLI
                                              </span>
                                            </span>
                                            {/* /.sc_button_text */}
                                          </a>
                                          {/* /.sc_button */}
                                        </div>
                                        {/* /.sc_item_button */}
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </section>
                          </div>
                        </div>
                      </div>
                    </section>
                    <section
                      className="elementor-section elementor-top-section elementor-element elementor-element-daabc28 elementor-section-stretched elementor-section-boxed elementor-section-height-default elementor-section-height-default elementor-invisible"
                      data-id="daabc28"
                      data-element_type="section"
                      data-settings='{"background_background":"classic","stretch_section":"section-stretched","animation":"fadeIn"}'
                    >
                      <div className="elementor-background-overlay" />
                      <div className="elementor-container elementor-column-gap-extended">
                        <div
                          className="elementor-column elementor-col-100 elementor-top-column elementor-element elementor-element-b3ba7eb sc_bg_mask_5 sc_layouts_column_icons_position_left"
                          data-id="b3ba7eb"
                          data-element_type="column"
                          data-settings='{"background_background":"classic"}'
                        >
                          <div className="elementor-widget-wrap elementor-element-populated">
                            <div
                              className="elementor-element elementor-element-4b5dcf5 sc_fly_static elementor-widget elementor-widget-spacer"
                              data-id="4b5dcf5"
                              data-element_type="widget"
                              data-widget_type="spacer.default"
                            >
                              <div className="elementor-widget-container">
                                <div className="elementor-spacer">
                                  <div className="elementor-spacer-inner" />
                                </div>
                              </div>
                            </div>
                            <div
                              className="elementor-element elementor-element-5d571ca scheme_dark sc_fly_static elementor-widget elementor-widget-trx_sc_testimonials"
                              data-id="5d571ca"
                              data-element_type="widget"
                              data-widget_type="trx_sc_testimonials.default"
                            >
                              <div className="elementor-widget-container">
                                <div
                                  id="trx_sc_testimonials_1606477044"
                                  className="sc_testimonials scheme_dark sc_testimonials_default"
                                >
                                  <div
                                    id="trx_sc_testimonials_1606477044_outer"
                                    className="sc_testimonials_slider sc_item_slider slider_swiper_outer slider_outer slider_outer_controls slider_outer_controls_bottom slider_outer_nopagination slider_outer_nocentered slider_outer_overflow_hidden slider_outer_one"
                                  >
                                    <div
                                      id="trx_sc_testimonials_1606477044_swiper"
                                      className="slider_container swiper-slider-container slider_swiper slider_noresize slider_controls slider_controls_bottom slider_nopagination slider_nocentered slider_overflow_hidden slider_one"
                                      data-slides-min-width={290}
                                      data-pagination="bullets"
                                      data-mouse-wheel={1}
                                      data-autoplay={1}
                                      data-slides-centered={0}
                                      data-slides-overflow={0}
                                    >
                                      <div className="slides slider-wrapper swiper-wrapper sc_item_columns_1">
                                        <div className="slider-slide swiper-slide">
                                          <div className="sc_testimonials_item">
                                            <div className="sc_testimonials_item_content">
                                              <p>
                                                “Attention politicians: the days
                                                of playing fast and loose with
                                                the truth are over. $POLI token
                                                is here to hold you accountable,
                                                and our memes aren’t going
                                                anywhere.”
                                              </p>
                                            </div>
                                            <div className="sc_testimonials_item_author">
                                              <div className="sc_testimonials_item_author_data">
                                                <h4 className="sc_testimonials_item_author_title">
                                                  POLITICOIN:
                                                </h4>
                                                <div className="sc_testimonials_item_author_subtitle">
                                                  $POLI
                                                </div>
                                              </div>
                                            </div>
                                          </div>
                                        </div>
                                        <div className="slider-slide swiper-slide">
                                          <div className="sc_testimonials_item">
                                            <div className="sc_testimonials_item_content">
                                              <p>
                                                “ Politicians, listen up: if you
                                                want to avoid being immortalized
                                                in our meme hall of shame, it’s
                                                time to step up and start taking
                                                your job seriously.”
                                              </p>
                                            </div>
                                            <div className="sc_testimonials_item_author">
                                              <div className="sc_testimonials_item_author_data">
                                                <h4 className="sc_testimonials_item_author_title">
                                                  POLITICOIN:
                                                </h4>
                                                <div className="sc_testimonials_item_author_subtitle">
                                                  $POLI
                                                </div>
                                              </div>
                                            </div>
                                          </div>
                                        </div>
                                        <div className="slider-slide swiper-slide">
                                          <div className="sc_testimonials_item">
                                            <div className="sc_testimonials_item_content">
                                              <p>
                                                “Dear politicians, a friendly
                                                reminder from $POLI token: take
                                                your job seriously, because our
                                                memes have a long memory.
                                                Forever memory!”
                                              </p>
                                            </div>
                                            <div className="sc_testimonials_item_author">
                                              <div className="sc_testimonials_item_author_data">
                                                <h4 className="sc_testimonials_item_author_title">
                                                  POLITICOIN:
                                                </h4>
                                                <div className="sc_testimonials_item_author_subtitle">
                                                  $POLI
                                                </div>
                                              </div>
                                            </div>
                                          </div>
                                        </div>
                                        <div className="slider-slide swiper-slide">
                                          <div className="sc_testimonials_item">
                                            <div className="sc_testimonials_item_content">
                                              <p>
                                                “To all politicians: if you
                                                think you can hide behind empty
                                                promises and half-hearted
                                                efforts, think again. $POLI
                                                token is watching, and our memes
                                                never forget.”
                                              </p>
                                            </div>
                                            <div className="sc_testimonials_item_author">
                                              <div className="sc_testimonials_item_author_data">
                                                <h4 className="sc_testimonials_item_author_title">
                                                  POLITICOIN:
                                                </h4>
                                                <div className="sc_testimonials_item_author_subtitle">
                                                  $POLI
                                                </div>
                                              </div>
                                            </div>
                                          </div>
                                        </div>
                                        <div className="slider-slide swiper-slide">
                                          <div className="sc_testimonials_item">
                                            <div className="sc_testimonials_item_content">
                                              <p>
                                                “Hey politicians, here’s a
                                                thought: if you want to avoid
                                                becoming the punchline of our
                                                next meme, maybe it’s time to
                                                start taking your
                                                responsibilities seriously.”
                                              </p>
                                            </div>
                                            <div className="sc_testimonials_item_author">
                                              <div className="sc_testimonials_item_author_data">
                                                <h4 className="sc_testimonials_item_author_title">
                                                  POLITICOIN:
                                                </h4>
                                                <div className="sc_testimonials_item_author_subtitle">
                                                  $POLI
                                                </div>
                                              </div>
                                            </div>
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                    <div className="slider_controls_wrap">
                                      <a
                                        className="slider_prev swiper-button-prev"
                                        href="#"
                                      />
                                      <a
                                        className="slider_next swiper-button-next"
                                        href="#"
                                      />
                                    </div>
                                  </div>
                                </div>
                                {/* /.sc_testimonials */}
                              </div>
                            </div>
                            <div
                              className="elementor-element elementor-element-2a39423 sc_fly_static elementor-widget elementor-widget-spacer"
                              data-id="2a39423"
                              data-element_type="widget"
                              data-widget_type="spacer.default"
                            >
                              <div className="elementor-widget-container">
                                <div className="elementor-spacer">
                                  <div className="elementor-spacer-inner" />
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </section>
                    <section
                      className="elementor-section elementor-top-section elementor-element elementor-element-67805b4 elementor-section-boxed elementor-section-height-default elementor-section-height-default elementor-invisible"
                      data-id="67805b4"
                      data-element_type="section"
                      data-settings='{"animation":"fadeIn"}'
                    >
                      <div className="elementor-container elementor-column-gap-extended">
                        <div
                          className="elementor-column elementor-col-100 elementor-top-column elementor-element elementor-element-44179f8 sc_layouts_column_icons_position_left"
                          data-id="44179f8"
                          data-element_type="column"
                        >
                          <div className="elementor-widget-wrap" />
                        </div>
                      </div>
                    </section>
                    <section
                      className="elementor-section elementor-top-section elementor-element elementor-element-4f4be92 elementor-section-boxed elementor-section-height-default elementor-section-height-default"
                      data-id="4f4be92"
                      data-element_type="section"
                    >
                      <div className="elementor-container elementor-column-gap-extended">
                        <div
                          className="elementor-column elementor-col-100 elementor-top-column elementor-element elementor-element-b6683d7 sc_layouts_column_icons_position_left"
                          data-id="b6683d7"
                          data-element_type="column"
                        >
                          <div className="elementor-widget-wrap" />
                        </div>
                      </div>
                    </section>
                    <section
                      className="elementor-section elementor-top-section elementor-element elementor-element-83503f3 elementor-section-boxed elementor-section-height-default elementor-section-height-default elementor-invisible"
                      data-id="83503f3"
                      data-element_type="section"
                      data-settings='{"animation":"fadeIn"}'
                    >
                      <div className="elementor-container elementor-column-gap-extended">
                        <div
                          className="elementor-column elementor-col-100 elementor-top-column elementor-element elementor-element-74b7b700 sc_layouts_column_icons_position_left"
                          data-id="74b7b700"
                          data-element_type="column"
                        >
                          <div className="elementor-widget-wrap elementor-element-populated">
                            <div
                              className="elementor-element elementor-element-4d45fb70 sc_fly_static elementor-invisible elementor-widget elementor-widget-trx_sc_services"
                              data-id="4d45fb70"
                              data-element_type="widget"
                              data-settings='{"_animation":"fadeIn"}'
                              data-widget_type="trx_sc_services.default"
                            >
                              <div className="elementor-widget-container">
                                <div
                                  id="trx_sc_services_295334706"
                                  className="sc_services sc_services_light sc_services_featured_left"
                                >
                                  <h2 className="sc_item_title sc_services_title sc_align_center sc_item_title_style_default">
                                    <span className="sc_item_title_text">
                                      MEME COIN FOR THE PEOPLE
                                    </span>
                                  </h2>
                                  <div className="sc_services_columns_wrap sc_item_columns sc_item_posts_container sc_item_columns_3 trx_addons_columns_wrap columns_padding_bottom">
                                    <div className="trx_addons_column-1_3">
                                      <div className="sc_services_item no_links without_content with_icon sc_services_item_featured_left post-342 cpt_services type-cpt_services status-publish has-post-thumbnail hentry cpt_services_group-issues">
                                        <span
                                          id="trx_sc_services_295334706_icon-check-3_1"
                                          className="sc_services_item_icon sc_icon_type_icons icon-check-3"
                                        />
                                        <div className="sc_services_item_info">
                                          <div className="sc_services_item_header">
                                            <h6 className="sc_services_item_title">
                                              Contract Renounced
                                            </h6>
                                            <div className="sc_services_item_subtitle">
                                              <span
                                                data-href="https://presale.politicoin.xyz/?cpt_services_group=issues"
                                                title="View all posts in Issues"
                                              >
                                                Issues
                                              </span>
                                            </div>
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                    <div className="trx_addons_column-1_3">
                                      <div className="sc_services_item no_links without_content with_icon sc_services_item_featured_left post-339 cpt_services type-cpt_services status-publish has-post-thumbnail hentry cpt_services_group-issues">
                                        <span
                                          id="trx_sc_services_295334706_icon-check-3_2"
                                          className="sc_services_item_icon sc_icon_type_icons icon-check-3"
                                        />
                                        <div className="sc_services_item_info">
                                          <div className="sc_services_item_header">
                                            <h6 className="sc_services_item_title">
                                              Professionally Audited
                                            </h6>
                                            <div className="sc_services_item_subtitle">
                                              <span
                                                data-href="https://presale.politicoin.xyz/?cpt_services_group=issues"
                                                title="View all posts in Issues"
                                              >
                                                Issues
                                              </span>
                                            </div>
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                    <div className="trx_addons_column-1_3">
                                      <div className="sc_services_item no_links without_content with_icon sc_services_item_featured_left post-336 cpt_services type-cpt_services status-publish has-post-thumbnail hentry cpt_services_group-issues">
                                        <span
                                          id="trx_sc_services_295334706_icon-check-3_3"
                                          className="sc_services_item_icon sc_icon_type_icons icon-check-3"
                                        />
                                        <div className="sc_services_item_info">
                                          <div className="sc_services_item_header">
                                            <h6 className="sc_services_item_title">
                                              Fair Tokenomics
                                            </h6>
                                            <div className="sc_services_item_subtitle">
                                              <span
                                                data-href="https://presale.politicoin.xyz/?cpt_services_group=issues"
                                                title="View all posts in Issues"
                                              >
                                                Issues
                                              </span>
                                            </div>
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                                {/* /.sc_services */}
                              </div>
                            </div>
                            <div
                              className="elementor-element elementor-element-b0ffdc1 sc_fly_static elementor-widget elementor-widget-trx_sc_title"
                              data-id="b0ffdc1"
                              data-element_type="widget"
                              data-settings='{"_animation":"none"}'
                              data-widget_type="trx_sc_title.default"
                            >
                              <div className="elementor-widget-container">
                                <div
                                  id="trx_sc_title_99230237"
                                  className="sc_title sc_title_default"
                                >
                                  <h2 className="sc_item_title sc_title_title sc_align_center sc_item_title_style_default">
                                    <span className="sc_item_title_text">
                                      United We Meme
                                    </span>
                                  </h2>
                                  <div className="sc_item_descr sc_title_descr sc_align_center">
                                    <p>
                                      Politicians, take heed: you will be
                                      remembered through our memes! The power of
                                      our community knows no bounds, knows no
                                      party lines. We stand united, because at
                                      the end of the day, it's the people who
                                      matter most.
                                    </p>
                                  </div>
                                </div>
                                {/* /.sc_title */}
                              </div>
                            </div>
                            <div
                              className="elementor-element elementor-element-350a0cf sc_fly_static elementor-widget elementor-widget-spacer"
                              data-id="350a0cf"
                              data-element_type="widget"
                              data-widget_type="spacer.default"
                            >
                              <div className="elementor-widget-container">
                                <div className="elementor-spacer">
                                  <div className="elementor-spacer-inner" />
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </section>
                    <section
                      className="elementor-section elementor-top-section elementor-element elementor-element-2a2a03c elementor-section-boxed elementor-section-height-default elementor-section-height-default elementor-invisible"
                      data-id="2a2a03c"
                      data-element_type="section"
                      data-settings='{"animation":"fadeIn"}'
                    >
                      <div className="elementor-container elementor-column-gap-extended">
                        <div
                          className="elementor-column elementor-col-50 elementor-top-column elementor-element elementor-element-93cb767 sc_layouts_column_icons_position_left"
                          data-id="93cb767"
                          data-element_type="column"
                          data-settings='{"animation":"none"}'
                        >
                          <div className="elementor-widget-wrap elementor-element-populated">
                            <div
                              className="elementor-element elementor-element-ef4923e sc_fly_static elementor-widget elementor-widget-spacer"
                              data-id="ef4923e"
                              data-element_type="widget"
                              data-widget_type="spacer.default"
                            >
                              <div className="elementor-widget-container">
                                <div className="elementor-spacer">
                                  <div className="elementor-spacer-inner" />
                                </div>
                              </div>
                            </div>
                            <section
                              className="elementor-section elementor-inner-section elementor-element elementor-element-db74ba8 elementor-section-boxed elementor-section-height-default elementor-section-height-default"
                              data-id="db74ba8"
                              data-element_type="section"
                              data-settings='{"background_background":"classic"}'
                            >
                              <div className="elementor-container elementor-column-gap-extended">
                                <div
                                  className="elementor-column elementor-col-100 elementor-inner-column elementor-element elementor-element-aba6e60 sc_layouts_column_icons_position_left"
                                  data-id="aba6e60"
                                  data-element_type="column"
                                >
                                  <div className="elementor-widget-wrap elementor-element-populated">
                                    <div
                                      className="elementor-element elementor-element-e54a732 sc_fly_static elementor-widget elementor-widget-trx_sc_title"
                                      data-id="e54a732"
                                      data-element_type="widget"
                                      data-widget_type="trx_sc_title.default"
                                    >
                                      <div className="elementor-widget-container">
                                        <div
                                          id="trx_sc_title_1103814755"
                                          className="sc_title sc_title_default"
                                        >
                                          <h5 className="sc_item_title sc_title_title sc_align_center sc_item_title_style_default sc_item_title_tag">
                                            <span className="sc_item_title_text">
                                              Join <i>Telegram</i>
                                            </span>
                                          </h5>
                                        </div>
                                        {/* /.sc_title */}
                                      </div>
                                    </div>
                                    <div
                                      className="elementor-element elementor-element-64c6885 elementor-widget-divider--view-line sc_fly_static elementor-widget elementor-widget-divider"
                                      data-id="64c6885"
                                      data-element_type="widget"
                                      data-widget_type="divider.default"
                                    >
                                      <div className="elementor-widget-container">
                                        <div className="elementor-divider">
                                          <span className="elementor-divider-separator"></span>
                                        </div>
                                      </div>
                                    </div>
                                    <div
                                      className="elementor-element elementor-element-3d93ef7 sc_fly_static elementor-widget elementor-widget-text-editor"
                                      data-id="3d93ef7"
                                      data-element_type="widget"
                                      data-widget_type="text-editor.default"
                                    >
                                      <div className="elementor-widget-container">
                                        <p style={{ textAlign: "center" }}>
                                          <a
                                            href="https://t.me/Politi_Coin"
                                            target="_blank"
                                            rel="noopener"
                                          >
                                            t.me/Politi_Coin
                                          </a>
                                        </p>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </section>
                          </div>
                        </div>
                        <div
                          className="elementor-column elementor-col-50 elementor-top-column elementor-element elementor-element-9c12153 sc_layouts_column_icons_position_left"
                          data-id="9c12153"
                          data-element_type="column"
                          data-settings='{"animation":"none"}'
                        >
                          <div className="elementor-widget-wrap elementor-element-populated">
                            <div
                              className="elementor-element elementor-element-bed1570 sc_fly_static elementor-widget elementor-widget-spacer"
                              data-id="bed1570"
                              data-element_type="widget"
                              data-widget_type="spacer.default"
                            >
                              <div className="elementor-widget-container">
                                <div className="elementor-spacer">
                                  <div className="elementor-spacer-inner" />
                                </div>
                              </div>
                            </div>
                            <section
                              className="elementor-section elementor-inner-section elementor-element elementor-element-2740097 elementor-section-boxed elementor-section-height-default elementor-section-height-default"
                              data-id={2740097}
                              data-element_type="section"
                              data-settings='{"background_background":"classic"}'
                            >
                              <div className="elementor-container elementor-column-gap-extended">
                                <div
                                  className="elementor-column elementor-col-100 elementor-inner-column elementor-element elementor-element-32adf12 sc_layouts_column_icons_position_left"
                                  data-id="32adf12"
                                  data-element_type="column"
                                >
                                  <div className="elementor-widget-wrap elementor-element-populated">
                                    <div
                                      className="elementor-element elementor-element-430032c sc_fly_static elementor-widget elementor-widget-trx_sc_title"
                                      data-id="430032c"
                                      data-element_type="widget"
                                      data-widget_type="trx_sc_title.default"
                                    >
                                      <div className="elementor-widget-container">
                                        <div
                                          id="trx_sc_title_1405275376"
                                          className="sc_title sc_title_default"
                                        >
                                          <h5 className="sc_item_title sc_title_title sc_align_center sc_item_title_style_default sc_item_title_tag">
                                            <span className="sc_item_title_text">
                                              Follow us on <i>X</i>
                                            </span>
                                          </h5>
                                        </div>
                                        {/* /.sc_title */}
                                      </div>
                                    </div>
                                    <div
                                      className="elementor-element elementor-element-c7a3643 elementor-widget-divider--view-line sc_fly_static elementor-widget elementor-widget-divider"
                                      data-id="c7a3643"
                                      data-element_type="widget"
                                      data-widget_type="divider.default"
                                    >
                                      <div className="elementor-widget-container">
                                        <div className="elementor-divider">
                                          <span className="elementor-divider-separator"></span>
                                        </div>
                                      </div>
                                    </div>
                                    <div
                                      className="elementor-element elementor-element-9f27219 sc_fly_static elementor-widget elementor-widget-text-editor"
                                      data-id="9f27219"
                                      data-element_type="widget"
                                      data-widget_type="text-editor.default"
                                    >
                                      <div className="elementor-widget-container">
                                        <p style={{ textAlign: "center" }}>
                                          <a
                                            href="https://twitter.com/politicoinxyz"
                                            target="_blank"
                                            rel="noopener"
                                          >
                                            @Politicoinxyz
                                          </a>
                                        </p>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </section>
                          </div>
                        </div>
                      </div>
                    </section>
                    <section
                      className="elementor-section elementor-top-section elementor-element elementor-element-58bd337 elementor-section-boxed elementor-section-height-default elementor-section-height-default"
                      data-id="58bd337"
                      data-element_type="section"
                    >
                      <div className="elementor-container elementor-column-gap-extended">
                        <div
                          className="elementor-column elementor-col-100 elementor-top-column elementor-element elementor-element-de74b31 sc_layouts_column_icons_position_left"
                          data-id="de74b31"
                          data-element_type="column"
                        >
                          <div className="elementor-widget-wrap elementor-element-populated">
                            <div
                              className="elementor-element elementor-element-a4765cd sc_height_huge sc_fly_static elementor-widget elementor-widget-spacer"
                              data-id="a4765cd"
                              data-element_type="widget"
                              data-widget_type="spacer.default"
                            >
                              <div className="elementor-widget-container">
                                <div className="elementor-spacer">
                                  <div className="elementor-spacer-inner" />
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </section>
                    <section
                      className="elementor-section elementor-top-section elementor-element elementor-element-15d17344 elementor-section-boxed elementor-section-height-default elementor-section-height-default elementor-invisible"
                      data-id="15d17344"
                      data-element_type="section"
                      data-settings='{"background_background":"classic","animation":"fadeIn"}'
                    >
                      <div className="elementor-container elementor-column-gap-extended">
                        <div
                          className="elementor-column elementor-col-100 elementor-top-column elementor-element elementor-element-7d4e66da sc_layouts_column_icons_position_left"
                          data-id="7d4e66da"
                          data-element_type="column"
                          data-settings='{"background_background":"classic"}'
                        >
                          <div className="elementor-widget-wrap" />
                        </div>
                      </div>
                    </section>
                    <section
                      className="elementor-section elementor-top-section elementor-element elementor-element-6fb5202 sc_layouts_hide_on_wide sc_layouts_hide_on_desktop sc_layouts_hide_on_notebook sc_layouts_hide_on_tablet sc_layouts_hide_on_mobile elementor-section-boxed elementor-section-height-default elementor-section-height-default"
                      data-id="6fb5202"
                      data-element_type="section"
                    >
                      <div className="elementor-container elementor-column-gap-extended">
                        <div
                          className="elementor-column elementor-col-100 elementor-top-column elementor-element elementor-element-aabad48 sc_layouts_column_icons_position_left"
                          data-id="aabad48"
                          data-element_type="column"
                        >
                          <div className="elementor-widget-wrap elementor-element-populated">
                            <div
                              className="elementor-element elementor-element-1e0865e sc_height_huge sc_fly_static elementor-widget elementor-widget-spacer"
                              data-id="1e0865e"
                              data-element_type="widget"
                              data-widget_type="spacer.default"
                            >
                              <div className="elementor-widget-container">
                                <div className="elementor-spacer">
                                  <div className="elementor-spacer-inner" />
                                </div>
                              </div>
                            </div>
                            <section
                              className="elementor-section elementor-inner-section elementor-element elementor-element-54efce4 elementor-section-boxed elementor-section-height-default elementor-section-height-default"
                              data-id="54efce4"
                              data-element_type="section"
                              data-settings='{"background_background":"classic"}'
                            >
                              <div className="elementor-container elementor-column-gap-extended">
                                <div
                                  className="elementor-column elementor-col-100 elementor-inner-column elementor-element elementor-element-ac6eae2 sc_layouts_column_icons_position_left"
                                  data-id="ac6eae2"
                                  data-element_type="column"
                                >
                                  <div className="elementor-widget-wrap elementor-element-populated">
                                    <div
                                      className="elementor-element elementor-element-39e0a20 sc_fly_static elementor-widget elementor-widget-spacer"
                                      data-id="39e0a20"
                                      data-element_type="widget"
                                      data-widget_type="spacer.default"
                                    >
                                      <div className="elementor-widget-container">
                                        <div className="elementor-spacer">
                                          <div className="elementor-spacer-inner" />
                                        </div>
                                      </div>
                                    </div>
                                    <div
                                      className="elementor-element elementor-element-35e60fa sc_fly_static elementor-widget elementor-widget-trx_sc_title"
                                      data-id="35e60fa"
                                      data-element_type="widget"
                                      data-widget_type="trx_sc_title.default"
                                    >
                                      <div className="elementor-widget-container">
                                        <div
                                          id="trx_sc_title_153374862"
                                          className="sc_title color_style_dark sc_title_accent"
                                        >
                                          <h4 className="sc_item_title sc_title_title sc_align_center sc_item_title_style_accent sc_item_title_tag trx_addons_inline_309656601">
                                            <span className="sc_item_title_text">
                                              Join Our Team and Make Us Stronger
                                            </span>
                                          </h4>
                                        </div>
                                        {/* /.sc_title */}
                                      </div>
                                    </div>
                                    <div
                                      className="elementor-element elementor-element-9c5e34e sc_fly_static elementor-widget elementor-widget-spacer"
                                      data-id="9c5e34e"
                                      data-element_type="widget"
                                      data-widget_type="spacer.default"
                                    >
                                      <div className="elementor-widget-container">
                                        <div className="elementor-spacer">
                                          <div className="elementor-spacer-inner" />
                                        </div>
                                      </div>
                                    </div>
                                    <div
                                      className="elementor-element elementor-element-fbb640f scheme_dark sc_fly_static elementor-widget elementor-widget-shortcode"
                                      data-id="fbb640f"
                                      data-element_type="widget"
                                      data-widget_type="shortcode.default"
                                    >
                                      <div className="elementor-widget-container">
                                        <div className="elementor-shortcode">
                                          [contact-form-7 id="1219"]
                                        </div>
                                      </div>
                                    </div>
                                    <div
                                      className="elementor-element elementor-element-0c9eded sc_fly_static elementor-widget elementor-widget-spacer"
                                      data-id="0c9eded"
                                      data-element_type="widget"
                                      data-widget_type="spacer.default"
                                    >
                                      <div className="elementor-widget-container">
                                        <div className="elementor-spacer">
                                          <div className="elementor-spacer-inner" />
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </section>
                          </div>
                        </div>
                      </div>
                    </section>
                  </div>
                </div>
                {/* .entry-content */}
              </article>
            </div>
            {/* </.content> */}
          </div>
          {/* </.content_wrap> */}
        </div>
        {/* </.page_content_wrap> */}

        {/* /.footer_wrap */}
      </div>
      {/* /.page_wrap */}
    </div>
  );
}

export default MainWebsite;

import { Alert, Box, Snackbar, TextField, useMediaQuery } from "@mui/material";
import { Button } from "@mui/material";
import { bnb, eth, pol, usdc, usdt } from "./Images";

export function ToastNotify({ alertState, setAlertState }) {
  return (
    <Snackbar
      anchorOrigin={{ vertical: "top", horizontal: "center" }}
      open={alertState.open}
      autoHideDuration={10000}
      key={"top center"}
      onClose={() => setAlertState({ ...alertState, open: false })}
    >
      <Alert
        onClose={() => setAlertState({ ...alertState, open: false })}
        severity={alertState.severity}
        variant="filled"
        sx={{ fontFamily: "Outfit" }}
      >
        {alertState.message}
      </Alert>
    </Snackbar>
  );
}

export function StyledButton({ children, ...props }) {
  return (
    <>
      <Button
        {...props}
        sx={{
          color: "#ffffff",
          background: "#C81724",
          fontSize: "18px",
          textTransform: "capitalize",
          fontFamily: "Outfit",
          borderRadius: "12px",
          width: props.width,
          boxShadow: "4px 4px 1px 1px #1f517d80",
          px: 2,
          "&.Mui-disabled": {
            color: "#979EA7",
          },
          "&:hover": {
            background: "#1f517d80",
          },
        }}
      >
        {children}
      </Button>
    </>
  );
}

export function CurrencyButton({ children, ...props }) {
  return (
    <>
      <Box
        {...props}
        sx={{
          color: "#ffffff",
          background: props.bgcolor,
          fontSize: "13px",
          fontFamily: "Outfit",
          fontWeight: "500",
          borderRadius: "8px",
          display: "flex",
          px: 2,
          py: 1,
          alignItems: "center",
          justifyContent: "center",
          mr: 1,
          cursor: "pointer",
        }}
      >
        <img
          width="17px"
          style={{ marginRight: "5px" }}
          src={
            children === "USDT"
              ? usdt
              : children === "USDC"
              ? usdc
              : children === "BNB"
              ? bnb
              : children === "POL"
              ? pol
              : eth
          }
          alt=""
        />{" "}
        {children}
      </Box>
    </>
  );
}

export function StyledText({ children, ...props }) {
  const matches = useMediaQuery("(max-width:600px)");
  return (
    <>
      <Box
        {...props}
        sx={{
          color: props.col,
          fontSize: matches ? "15px" : "17px",
          fontWeight: props.fontWeight,
          fontFamily: "Outfit",
          lineHeight: "25px",
          mr: props.mr,
          mt: props.mt,
        }}
      >
        {children}
      </Box>
    </>
  );
}
export function StyledTitle({ children, ...props }) {
  return (
    <>
      <Box
        {...props}
        sx={{
          color: "#000000",
          fontSize: "30px",
          fontFamily: "Outfit",
          fontWeight: "700",
          mr: props.mr,
        }}
      >
        {children}
      </Box>
    </>
  );
}
export const StyledInput = ({ ...props }) => {
  return (
    <TextField
      {...props}
      sx={{
        background: "#1f517d80 !important",
        borderRadius: "7px",
        border: "2px solid #C81724",
        width: "100%",
        height: "55px",
        pt: 0.8,
        "& .MuiOutlinedInput-root": {
          borderRadius: "10px",
          "& fieldset": {
            border: "none",
          },
          "&:hover fieldset": {
            border: "none",
          },
          "&.Mui-focused fieldset": {
            border: "none",
          },
        },

        input: {
          "&::placeholder": {
            color: "#ffffff !important",
            opacity: 1,
          },
          background: "transparent !important",
          border: "none !important",
          padding: { xs: "16px 6px", sm: "18px 4px" },
          color: "#ffffff !important",
          fontSize: "17px",
          fontWeight: "600",
          fontFamily: "Outfit",
        },
      }}
    />
  );
};

import {
  Box,
  Container,
  Grid,
  useMediaQuery,
  Stack,
  InputAdornment,
  Button,
  Typography,
  Divider,
} from "@mui/material";
import { useSwitchNetwork } from "wagmi";
import { useWeb3Modal } from "@web3modal/wagmi/react";
import React, { useContext, useEffect, useState } from "react";
import CircleIcon from "@mui/icons-material/Circle";
import { fetchBalance } from "@wagmi/core";
import {
  StyledText,
  ToastNotify,
  StyledInput,
  CurrencyButton,
  StyledButton,
} from "../components/SmallComponents/AppComponents";
import Loading from "../components/SmallComponents/loading";
import { AppContext, getNetworkContract } from "../utils";
import { eth, logo, usdc, usdt } from "../components/SmallComponents/Images";
import {
  preSaleReadFunction,
  preSaleWriteFunction,
  tokenReadFunction,
  usdcReadFunction,
  usdcWriteFunction,
  usdtReadFunction,
  usdtWriteFunction,
} from "../ConnectivityAssets/hooks";
import { formatUnits, parseUnits } from "viem";
import { preSaleAddress } from "../ConnectivityAssets/environment";
import TimerCountDown from "../components/SmallComponents/PresaleTimer";

export default function PresalePage() {
  const matches = useMediaQuery("(max-width:700px)");
  const { switchNetwork } = useSwitchNetwork();
  const { open } = useWeb3Modal();
  const mobileMatches = useMediaQuery("(max-width:650px)");
  let startTime = 1714953600;
  const { account, chainId } = useContext(AppContext);
  const [buyWith, setbuyWith] = useState("USDT");
  const [buyingNetwork, setBuyingNetwork] = useState("ETH");
  const [amountToBuy, setamountToBuy] = useState("");
  const [totalSoldTokens, settotalSoldTokens] = useState(0);
  const [callFunction, setCallFunction] = useState(true);
  const [tokenPerETH, settokenPerETH] = useState(0);
  const [tokenPerUSDT, settokenPerUSDT] = useState(0);
  const [userPurchasedTokens, setuserPurchasedTokens] = useState(0);
  const [tokenPrice, settokenPrice] = useState(0);
  const [isPresaleStart, setisPresaleStart] = useState(false);
  const [currentStage, setcurrentStage] = useState(0);
  const [isClaimEnabled, setisClaimEnabled] = useState(false);
  const [preSaleEndedStatus, setPreSaleEndedStatus] = useState(false);
  const [loading, setloading] = useState(false);
  const [decimal, setdecimal] = useState(0);
  const [receivedTokens, setreceivedTokens] = useState(0);
  const [totalSupply, settotalSupply] = useState(0);
  const [totalRaised, settotalRaised] = useState(0);
  const [progressBar, setprogressBar] = useState(0);
  const [usdtBalance, setusdtBalance] = useState(0);
  const [usdcBalance, setusdcBalance] = useState(0);
  const [ethBalance, setethBalance] = useState(0);
  const [alertState, setAlertState] = useState({
    open: false,
    message: "",
    severity: undefined,
  });
  const showAlert = (message, severity = "error") => {
    setAlertState({
      open: true,
      message,
      severity,
    });
  };
  const toLocalFormat = (val) => {
    return val.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
  };
  const initVoidSigner = async () => {
    try {
      const usdtToToken = await preSaleReadFunction("usdtToToken", ["1000000"]);
      const ethToToken = await preSaleReadFunction("nativeToToken", [
        "1000000000000000000",
      ]);
      settokenPerUSDT(+formatUnits(usdtToToken?.toString(), 18));
      settokenPerETH(+formatUnits(ethToToken?.toString(), 18));
      let sold = await preSaleReadFunction("soldToken");
      let price = await preSaleReadFunction("tokenPerUsdPrice");
      settokenPrice(+formatUnits(price?.toString(), 18));
      let statusPresale = await preSaleReadFunction("isPresaleEnded");
      let start = await preSaleReadFunction("presaleStatus");
      setisClaimEnabled(statusPresale);
      setisPresaleStart(start);
      sold = +parseFloat(+formatUnits(sold?.toString(), 18)).toFixed(0);
      settotalSoldTokens(toLocalFormat(sold));
      const raisedUSDT = await preSaleReadFunction("amountRaisedOverall");
      let total = +parseFloat(+formatUnits(raisedUSDT?.toString(), 6)).toFixed(
        0
      );
      settotalRaised(toLocalFormat(total));
      let progress = (total / 45000000) * 100;
      setprogressBar(progress);
      setCallFunction(false);
    } catch (error) {
      setCallFunction(false);
      console.log(error, "ERROR VoidSigner Data");
    }
  };
  useEffect(() => {
    initVoidSigner();
  }, [callFunction]);

  const userTokenFunction = async () => {
    try {
      let dec = await tokenReadFunction("decimals");
      let userData = await preSaleReadFunction("users", [account]);
      let balanceUSDC = await usdcReadFunction("balanceOf", [account]);
      let balanceUSDT = await usdtReadFunction("balanceOf", [account]);
      balanceUSDC = +parseFloat(
        +formatUnits(balanceUSDC?.toString(), 6)
      ).toFixed(0);
      balanceUSDT = +parseFloat(
        +formatUnits(balanceUSDT?.toString(), 6)
      ).toFixed(0);
      setusdcBalance(toLocalFormat(balanceUSDC));
      setusdtBalance(toLocalFormat(balanceUSDT));
      let totalPurchasedUser = +formatUnits(
        userData[3]?.toString(),
        +dec?.toString()
      );
      setuserPurchasedTokens(parseFloat(totalPurchasedUser)?.toFixed(0));
      let ethBal = await fetchBalance({
        address: account,
      });
      ethBal = +parseFloat(+formatUnits(ethBal?.value?.toString(), 18)).toFixed(
        3
      );
      setethBalance(toLocalFormat(ethBal));
    } catch (e) {
      console.log(e);
    }
  };
  useEffect(() => {
    if (account) {
      userTokenFunction();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [account, chainId]);

  const buyHandler = async () => {
    if (!account) {
      return showAlert("Error! Please connect your wallet.");
    }
    if (!amountToBuy || amountToBuy <= 0) {
      return showAlert("Error! Please enter amount to buy.");
    }
    try {
      setloading(true);
      if (buyWith === "USDT") {
        const usdtDecimal = await usdtReadFunction("decimals");
        await usdtWriteFunction("approve", [
          preSaleAddress,
          parseUnits(
            amountToBuy.toString(),
            +usdtDecimal?.toString()
          ).toString(),
        ]);
        await preSaleWriteFunction("buyTokenUSDT", [
          parseUnits(
            amountToBuy.toString(),
            +usdtDecimal?.toString()
          ).toString(),
        ]);
      } else if (buyWith === "USDC") {
        const usdcDecimal = await usdcReadFunction("decimals");
        await usdcWriteFunction("approve", [
          preSaleAddress,
          parseUnits(
            amountToBuy.toString(),
            +usdcDecimal?.toString()
          ).toString(),
        ]);
        await preSaleWriteFunction("buyTokenUSDC", [
          parseUnits(
            amountToBuy.toString(),
            +usdcDecimal?.toString()
          ).toString(),
        ]);
      } else {
        await preSaleWriteFunction(
          "buyToken",
          [],
          parseUnits(amountToBuy.toString(), 18).toString()
        );
      }
      setamountToBuy("");
      setreceivedTokens(0);
      setCallFunction(true);
      userTokenFunction();
      setloading(false);
      showAlert("Success! Transaction Confirmed", "success");
    } catch (error) {
      setloading(false);
      console.log(error);
      showAlert(error?.shortMessage);
    }
  };

  useEffect(() => {
    const calculatorUSDT = async () => {
      try {
        if (buyWith === "USDT" || buyWith === "USDC") {
          let tokenUSDT = +tokenPerUSDT * +amountToBuy;
          setreceivedTokens(tokenUSDT?.toFixed(2));
        } else {
          let tokenETH = +tokenPerETH * +amountToBuy;
          setreceivedTokens(tokenETH?.toFixed(2));
        }
      } catch (error) {}
    };
    if (+amountToBuy > 0) {
      calculatorUSDT();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [amountToBuy, buyWith]);

  const handleInputChange = (event) => {
    const input = event.target.value;
    const newValue = input?.replace(/[^0-9.]/g, "");
    setamountToBuy(newValue);
  };
  const claimHandler = async () => {
    if (account) {
      try {
        setloading(true);
        await preSaleWriteFunction("claimTokens");
        setloading(false);
        setAlertState({
          open: true,
          message: `Transection Completed!`,
          severity: "success",
        });
      } catch (error) {
        setloading(false);
        setAlertState({
          open: true,
          message: error?.shortMessage,
          severity: "error",
        });
      }
    } else {
      setAlertState({
        open: true,
        message: `Error! Please connect your wallet.`,
        severity: "error",
      });
    }
  };

  return (
    <Box pb={10} id="presale">
      <ToastNotify alertState={alertState} setAlertState={setAlertState} />
      <Loading loading={loading} />
      <Container maxWidth="xl">
        <Grid
          container
          spacing={matches ? 2 : 2}
          display="flex"
          justifyContent="center"
          flexWrap="wrap-reverse"
        >
          <Grid item xs={12} md={5}>
            <Box
              sx={{
                boxShadow: "0px 0px 1px 5px #1F517D",
                // border: "4px solid #505050",
                borderRadius: "10px",
                textAlign: "center",
              }}
            >
              <Box
                sx={{
                  p: 3,
                }}
              >
                <Stack
                  flexDirection={"row"}
                  justifyContent="center"
                  alignItems={"center"}
                  gap={1}
                >
                  {isPresaleStart && (
                    <Box
                      sx={{
                        borderRadius: "50px",
                        backgroundColor: "#21C107",
                        py: 1,
                        px: 2,
                      }}
                    >
                      <Typography
                        variant="h5"
                        sx={{
                          fontFamily: "Outfit",
                          fontSize: { xs: "10px", sm: "12px" },
                          fontWeight: "600",
                          color: "#ffffff",
                        }}
                      >
                        Live
                      </Typography>
                    </Box>
                  )}
                </Stack>
                <Stack
                  flexDirection={{ xs: "column-reverse", sm: "row" }}
                  justifyContent={{ xs: "center", sm: "center" }}
                  alignItems={{ xs: "center", sm: "start" }}
                  gap={{ xs: 1, sm: 0 }}
                  my={1}
                >
                  <Typography
                    sx={{
                      fontFamily: "Outfit",
                      fontSize: { xs: "20px", sm: "26px" },
                      fontWeight: "800",
                      color: "#ffffff",
                      textAlign: "center",
                    }}
                  >
                    SINGLE STAGE PRESALE!
                  </Typography>
                </Stack>
                {/* <Stack
                  sx={{
                    height: "30px",
                    background: "#1f517d80",
                    mt: 0.5,
                    borderRadius: "20px",
                    border: "1px solid #C81724",
                    overflow: "hidden",
                    mx: { xs: 0, sm: 1 },
                  }}
                >
                  <Stack
                    height={"100%"}
                    alignItems={"start"}
                    justifyContent={"center"}
                    position={"relative"}
                  >
                    <Box
                      sx={{
                        position: "absolute",
                        boxShadow:
                          "4.073208808898926px 4.073208808898926px 10.183022499084473px 0px #FFFFFFCC inset,0px 4.493834972381592px 9.986300468444824px 0px #A201274D",
                        left: 0,
                        top: 0,
                        bottom: 0,
                        width: `${progressBar}%`,
                        borderRadius: "20px",
                        backgroundColor: "#C81724",
                        zIndex: 0,
                      }}
                    />
                    <Typography
                      variant="subtitle2"
                      sx={{
                        color: "#ffffff",
                        fontFamily: "Outfit",
                        fontSize: "11px",
                        fontWeight: "600",
                        zIndex: 1,
                        pl: 1.2,
                      }}
                    >
                      {parseFloat(progressBar)?.toFixed(2)}%
                    </Typography>
                  </Stack>
                </Stack>
                <Stack flexDirection={"row"} justifyContent={"flex-end"} my={1}>
                  <Typography
                    variant="subtitle2"
                    sx={{
                      color: "#d1d1d1",
                      fontFamily: "Outfit",
                      fontSize: { xs: "10px", sm: "12px" },
                      fontWeight: "600",
                      pr: 1.2,
                    }}
                  >
                    Raised ${totalRaised}
                  </Typography>
                </Stack>
                <Stack
                  sx={{
                    flexDirection: "row",
                    justifyContent: "center",
                    alignItems: "center",
                    my: 1,
                  }}
                >
                  <Box
                    sx={{
                      height: "2px",
                      // width: "50%",
                      background: "#C81724",
                      flexGrow: 1,
                    }}
                  />
                  <Box
                    display={"flex"}
                    flexDirection={{ xs: "column", sm: "row" }}
                    gap={{ xs: 0.5, sm: 1, md: 0.8, xl: 1, lg: 1 }}
                    mx={{ xs: 1, md: 0.5, lg: 1, xl: 1 }}
                    alignItems={"center"}
                    justifyContent={"center"}
                  >
                    <Typography
                      variant="subtitle2"
                      sx={{
                        fontSize: { xs: "10px", sm: "12px" },
                        textAlign: "center",
                        fontWeight: "700",
                        color: "#d1d1d1",
                        fontFamily: "Outfit",
                      }}
                    >
                      1 $POLI = $
                      {+tokenPrice > 0
                        ? parseFloat(1 / +tokenPrice).toFixed(2)
                        : "0.00"}
                    </Typography>

                    <CircleIcon
                      sx={{
                        color: "#D9D9D9",
                        fontSize: "9px",
                      }}
                    />
                    <Typography
                      variant="subtitle2"
                      sx={{
                        fontSize: { xs: "10px", sm: "12px" },
                        textAlign: "center",
                        fontWeight: "700",
                        color: "#d1d1d1",
                        fontFamily: "Outfit",
                      }}
                    >
                      LISTING PRICE = $0.003
                    </Typography>
                  </Box>

                  <Box
                    sx={{
                      height: "2px",
                      flexGrow: 1,
                      background: "#C81724",
                    }}
                  />
                </Stack>
                <Stack
                  my={1}
                  sx={{
                    mx: { xs: 0, sm: 1 },
                    px: 2,
                    py: 1,
                    borderRadius: "12px",
                    flexDirection: { xs: "column", sm: "row" },
                    gap: { xs: 1, sm: 0 },
                    // justifyContent: "space-around",
                    background: "#1f517d80",
                  }}
                >
                  <Stack flexGrow={1}>
                    <Typography
                      variant="subtitle2"
                      sx={{
                        color: "#ffff",
                        fontFamily: "Outfit",
                        fontSize: "14px",
                        fontWeight: "700",
                        textAlign: "left",
                      }}
                    >
                      TOKENS SOLD
                    </Typography>
                    <Stack
                      flexDirection={"row"}
                      alignItems="center"
                      my={1}
                      gap={0.5}
                    >
                      <Box
                        height={"100%"}
                        display={"flex"}
                        justifyContent={"center"}
                        alignItems={"center"}
                      >
                        <Box
                          component={"img"}
                          alt=""
                          src={logo}
                          sx={{ width: "30px", mt: { xs: 1, sm: 0 } }}
                        />
                      </Box>
                      <Box>
                        <Typography
                          variant="subtitle2"
                          sx={{
                            color: "#ffff",
                            fontFamily: "Outfit",
                            fontSize: { xs: "14px", sm: "17px" },
                            fontWeight: "700",
                            textAlign: "left",
                          }}
                        >
                          {totalSoldTokens}
                          <br />
                          <span
                            style={{
                              fontWeight: "600",
                              fontSize: { xs: "10px", sm: "9px" },
                            }}
                          >
                            / 900,000,000
                          </span>
                        </Typography>
                      </Box>
                    </Stack>
                  </Stack>
                  {mobileMatches ? (
                    <Divider
                      sx={{
                        background: "#353535",
                      }}
                    />
                  ) : (
                    <Divider
                      orientation="vertical"
                      variant="middle"
                      flexItem
                      sx={{
                        background: "#353535",
                        mr: 1,
                      }}
                    />
                  )}

                  <Stack flexGrow={1}>
                    <Typography
                      variant="subtitle2"
                      sx={{
                        color: "#ffff",
                        fontFamily: "Outfit",
                        fontSize: "14px",
                        fontWeight: "700",
                        textAlign: "left",
                      }}
                    >
                      USD RAISED
                    </Typography>
                    <Stack
                      flexDirection={"row"}
                      alignItems="center"
                      mt={1}
                      gap={0.5}
                    >
                      <Box
                        height={"100%"}
                        display={"flex"}
                        justifyContent={"center"}
                        alignItems={"center"}
                      >
                        <Box
                          component={"img"}
                          alt=""
                          src={usdt}
                          sx={{ width: "30px", mt: { xs: 1, sm: 0 } }}
                        />
                      </Box>
                      <Box>
                        <Typography
                          variant="subtitle2"
                          sx={{
                            color: "#ffff",
                            fontFamily: "Outfit",
                            fontSize: { xs: "14px", sm: "17px" },
                            fontWeight: "700",
                            textAlign: "left",
                          }}
                        >
                          {totalRaised}
                          <br />
                          <span
                            style={{
                              fontWeight: "600",
                              fontSize: { xs: "10px", sm: "9px" },
                            }}
                          >
                            / $45,000,000
                          </span>
                        </Typography>
                      </Box>
                    </Stack>
                  </Stack>
                </Stack>
                <Stack
                  sx={{
                    flexDirection: "row",
                    justifyContent: "center",
                    alignItems: "center",
                    my: 1,
                  }}
                >
                  <Box
                    sx={{
                      height: "2px",
                      // width: "50%",
                      background: "#C81724",
                      flexGrow: 1,
                    }}
                  />

                  <Typography
                    variant="subtitle2"
                    sx={{
                      fontSize: { xs: "10px", sm: "13px" },
                      textAlign: "center",
                      fontWeight: "700",
                      color: "#d1d1d1",
                      fontFamily: "Outfit",
                      mx: 2,
                      // width: "120px",
                    }}
                  >
                    {account ? (
                      <StyledText>
                        Balance:{" "}
                        {buyWith === "USDT"
                          ? usdtBalance
                          : buyWith === "ETH"
                          ? ethBalance
                          : usdcBalance}{" "}
                        {buyWith === "USDT"
                          ? "USDT"
                          : buyWith === "ETH"
                          ? "ETH"
                          : "USDC"}
                      </StyledText>
                    ) : (
                      "CONNECT WALLET"
                    )}
                  </Typography>

                  <Box
                    sx={{
                      height: "2px",
                      flexGrow: 1,
                      // width: "50%",
                      background: "#C81724",
                    }}
                  />
                </Stack> */}
                {/* <StyledText fontWeight="500" col="#C81724">
                  Presale Starts In
                </StyledText>
                {!isPresaleStart && <TimerCountDown time={+startTime} />} */}
                <StyledText mt={3} fontWeight="700" col="#C81724">
                  Choose Token
                </StyledText>
                <Box
                  display="flex"
                  alignItems="center"
                  mt={1}
                  mb={2}
                  justifyContent="center"
                >
                  <CurrencyButton
                    bgcolor={buyWith === "USDT" ? "#C81724" : "#1f517d80"}
                    color={buyWith === "USDT" ? "#ffffff" : "#C81724"}
                    onClick={() => setbuyWith("USDT")}
                  >
                    USDT
                  </CurrencyButton>

                  <CurrencyButton
                    bgcolor={buyWith === "ETH" ? "#C81724" : "#1f517d80"}
                    color={buyWith === "ETH" ? "#ffffff" : "#C81724"}
                    onClick={() => setbuyWith("ETH")}
                  >
                    ETH
                  </CurrencyButton>

                  <CurrencyButton
                    bgcolor={buyWith === "USDC" ? "#C81724" : "#1f517d80"}
                    color={buyWith === "USDC" ? "#ffffff" : "#C81724"}
                    onClick={() => setbuyWith("USDC")}
                  >
                    USDC
                  </CurrencyButton>
                </Box>
                <a
                  href="https://www.moonpay.com/"
                  style={{
                    textDecorationColor: "#C81724",
                  }}
                >
                  <StyledText fontWeight="400" col="#ffffff">
                    Buy With Credit Card
                  </StyledText>
                </a>
                <Stack
                  sx={{
                    flexDirection: { xs: "column", sm: "row" },
                    gap: { xs: 1, sm: 2 },
                    my: 2,
                  }}
                >
                  <Box>
                    <StyledInput
                      type="text"
                      placeholder="Enter Amount"
                      InputProps={{
                        startAdornment: (
                          <InputAdornment position="end">
                            <Box
                              component={"img"}
                              alt=""
                              src={
                                buyWith === "USDT"
                                  ? usdt
                                  : buyWith === "USDC"
                                  ? usdc
                                  : eth
                              }
                              style={{
                                width: "46px",
                                marginLeft: "-10px",
                              }}
                            />
                          </InputAdornment>
                        ),
                      }}
                      value={amountToBuy}
                      onChange={handleInputChange}
                    />
                  </Box>
                  <Box>
                    <StyledInput
                      type="number"
                      placeholder="0"
                      value={amountToBuy > 0 ? receivedTokens : "0"}
                      InputProps={{
                        readOnly: true,
                        startAdornment: (
                          <InputAdornment position="end">
                            <Box
                              component={"img"}
                              alt=""
                              src={logo}
                              style={{
                                width: "46px",
                                marginLeft: "-10px",
                              }}
                            />
                          </InputAdornment>
                        ),
                      }}
                    />
                  </Box>
                </Stack>
                <Stack my={1} gap={1}>
                  {!isClaimEnabled && (
                    <StyledButton
                      onClick={async () =>
                        account ? buyHandler() : await open()
                      }
                    >
                      {" "}
                      {account ? "BUY" : "CONNECT"}
                    </StyledButton>
                  )}

                  {isClaimEnabled && account ? (
                    <StyledButton onClick={async () => claimHandler()}>
                      Claim $POLI
                    </StyledButton>
                  ) : null}
                  {account && (
                    <StyledButton onClick={() => open()}>
                      Disconnect ({account.slice(0, 7) + "..."})
                    </StyledButton>
                  )}
                </Stack>
                <Box
                  mt={1}
                  display="flex"
                  alignItems="center"
                  justifyContent="center"
                >
                  <StyledText fontWeight="500" col="#ffffff">
                    Your Purchased:
                  </StyledText>
                  <Box ml={0.5} />
                  <StyledText fontWeight="700" col="#C81724">
                    {userPurchasedTokens} $POLI
                  </StyledText>
                </Box>
              </Box>
            </Box>
          </Grid>
        </Grid>
      </Container>
    </Box>
  );
}

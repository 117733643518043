import React from "react";
import {
  Container,
  Hidden,
  useMediaQuery,
  SwipeableDrawer,
  Button,
  List,
  ListItem,
  ListItemText,
  Box,
  Paper,
} from "@mui/material";
import { makeStyles } from "@mui/styles";
import MenuIcon from "@mui/icons-material/Menu";
import clsx from "clsx";
import { logo } from "./SmallComponents/Images";

// import { AppContext } from "../utils";
import { StyledButton, StyledText } from "./SmallComponents/AppComponents";
import { ExampleButton } from "./SmallComponents/StyledWalletButton";

const useStyles = makeStyles({
  list: {
    width: 250,
  },
  fullList: {
    width: "auto",
    alignItems: "center",
  },
  paper: {
    background: "#253551 !important",
    paddingBottom: "20px",
  },
  hover: {
    "&:hover": {
      color: "#FFB800",
    },
  },
});

export default function MainHeader() {
  // const { account, connect, disconnect } = useContext(AppContext);
  const classes = useStyles();
  const [state, setState] = React.useState({
    left: false,
  });
  const matches1 = useMediaQuery("(max-width:900px)");

  const toggleDrawer = (anchor, open) => (event) => {
    if (
      event &&
      event.type === "keydown" &&
      (event.key === "Tab" || event.key === "Shift")
    ) {
      return;
    }
    setState({ ...state, [anchor]: open });
  };
  const list = (anchor) => (
    <div
      className={clsx(classes.list, {
        [classes.fullList]: anchor === "top" || anchor === "bottom",
      })}
      role="presentation"
      onClick={toggleDrawer(anchor, false)}
      onKeyDown={toggleDrawer(anchor, false)}
    >
      <Box display="flex" justifyContent="center">
        <a href="/">
          <img width="150px" src={logo} alt="" />
        </a>
      </Box>
      {[
        { name: "ROADMAP", link: "/poli-roadmap.pdf" },
        { name: "WHITEPAPER", link: "/poli-whitepaper.pdf" },
        { name: "$POLI AUDIT", link: "/poli-audit.pdf" },
        {
          name: "$25K GIVEAWAY",
          link: "https://gleam.io/KZQfs/politicoin-25000-giveaway",
        },
      ].map(({ name, link }, index) => (
        <a href={link} target="_blank">
          <Box
            sx={{
              justifyContent: "center",
              fontFamily: "Outfit !important",
              fontSize: "20px",
              marginTop: "20px",
              textAlign: "center",
              color: "#ffffff",
            }}
            key={index}
          >
            {name}
          </Box>
        </a>
      ))}
      <Box mt={3} textAlign="center">
        <a href="/#presale">
          <StyledButton>BUY $POLI</StyledButton>
        </a>
        <Box mt={2} display="flex" alignItems="center" justifyContent="center">
          <a
            className="elementor-icon"
            href="https://t.me/Politi_Coin"
            target="_blank"
            style={{ color: "#d1d1d1" }}
          >
            <i aria-hidden="true" className="fab fa-telegram-plane" />
          </a>
          <a
            className="elementor-icon"
            href="https://twitter.com/politicoinxyz"
            target="_blank"
            style={{
              marginLeft: "10px",
              marginRight: "10px",
              color: "#d1d1d1",
            }}
          >
            <i aria-hidden="true" className="fab fa-x-twitter" />
          </a>
        </Box>
      </Box>
    </div>
  );
  return (
    <>
      <Box
        sx={{
          background: "transparent",
        }}
        height="92px"
        width="100%"
        pt={2}
      >
        <Container maxWidth="xl">
          <Box
            display="flex"
            justifyContent="space-between"
            alignItems="center"
          >
            <Box display="flex" alignItems="center">
              <a href="/" style={{ display: "flex", alignItems: "center" }}>
                <img width="60px" src={logo} alt="" />

                {!matches1 && (
                  <h2
                    style={{ marginTop: "10px" }}
                    className="sc_item_title sc_title_title sc_align_center sc_item_title_style_default sc_item_title_tag"
                  >
                    <span className="sc_item_title_text">POLITICOIN</span>
                  </h2>
                )}
              </a>
            </Box>
            <Box
              display="flex"
              justifyContent={matches1 ? "end" : "space-between"}
              alignItems="center"
            >
              <Hidden mdDown>
                <a href="/poli-roadmap.pdf" target="_blank">
                  <StyledText col="#253551" mr={3}>
                    ROADMAP
                  </StyledText>
                </a>
                <a href="/poli-whitepaper.pdf" target="_blank">
                  <StyledText col="#253551" mr={3}>
                    WHITEPAPER
                  </StyledText>
                </a>
                <a href="/poli-audit.pdf" target="_blank">
                  <StyledText col="#253551" mr={3}>
                    $POLI AUDIT
                  </StyledText>
                </a>
                <a
                  href="https://gleam.io/KZQfs/politicoin-25000-giveaway"
                  target="_blank"
                >
                  <StyledText col="#253551" mr={3}>
                    $25K GIVEAWAY
                  </StyledText>
                </a>

                <Box display="flex" alignItems="center">
                  <a
                    className="elementor-icon"
                    href="https://t.me/Politi_Coin"
                    target="_blank"
                    style={{ color: "#253551" }}
                  >
                    <i aria-hidden="true" className="fab fa-telegram-plane" />
                  </a>
                  <a
                    className="elementor-icon"
                    href="https://twitter.com/politicoinxyz"
                    target="_blank"
                    style={{
                      marginLeft: "10px",
                      marginRight: "10px",
                      color: "#253551",
                    }}
                  >
                    <i aria-hidden="true" className="fab fa-x-twitter" />
                  </a>
                  <a href="/#presale">
                    <StyledButton>BUY $POLI</StyledButton>
                  </a>
                </Box>
              </Hidden>

              <Hidden mdUp>
                {["top"].map((anchor) => (
                  <React.Fragment key={anchor}>
                    <Button
                      onClick={toggleDrawer(anchor, true)}
                      style={{ zIndex: 1 }}
                    >
                      <MenuIcon
                        style={{
                          fontSize: "38px",
                          cursor: "pointer",
                          color: "#000000",
                        }}
                      ></MenuIcon>
                    </Button>
                    <Paper>
                      <SwipeableDrawer
                        classes={{ paper: classes.paper }}
                        anchor={anchor}
                        open={state[anchor]}
                        onClose={toggleDrawer(anchor, false)}
                        onOpen={toggleDrawer(anchor, true)}
                      >
                        {list(anchor)}
                      </SwipeableDrawer>
                    </Paper>
                  </React.Fragment>
                ))}
              </Hidden>
            </Box>
          </Box>
        </Container>
      </Box>
    </>
  );
}

import { useContext, useState, useEffect } from "react";
import Header from "./components/Header";
import { useNetwork } from "wagmi";
import { AppContext } from "./utils";
import NetworkSwitch from "./NetworkSwitch";
import MainWebsite from "./pages/MainWebsite";
import Footer from "./components/Footer";
import MainHeader from "./components/MainHeader";
import { Route, Routes } from "react-router-dom";
import Blog from "./pages/Blog";
function App() {
  const { account } = useContext(AppContext);
  const [openNetworkSwitch, setOpenNetworkSwitch] = useState(false);
  const { chain } = useNetwork();

  useEffect(() => {
    if (account && chain && chain?.id !== 1) {
      setOpenNetworkSwitch(true);
    }
  }, [chain, account]);
  return (
    <>
      <NetworkSwitch open={openNetworkSwitch} setOpen={setOpenNetworkSwitch} />
      <MainHeader />
      <Routes>
        <Route path="/" element={<MainWebsite />} />
        <Route path="/blog" element={<Blog />} />
      </Routes>
      <Footer />
    </>
  );
}

export default App;

import React from "react";

function Footer() {
  return (
    <footer className="footer_wrap footer_custom footer_custom_496 footer_custom_footer-home-1-and-3">
      <div
        data-elementor-type="cpt_layouts"
        data-elementor-id={496}
        className="elementor elementor-496"
      >
        <section
          className="elementor-section elementor-top-section elementor-element elementor-element-eac0f38 elementor-section-stretched sc_layouts_hide_on_wide sc_layouts_hide_on_desktop sc_layouts_hide_on_notebook sc_layouts_hide_on_tablet sc_layouts_hide_on_mobile elementor-section-boxed elementor-section-height-default elementor-section-height-default elementor-invisible"
          data-id="eac0f38"
          data-element_type="section"
          data-settings='{"stretch_section":"section-stretched","background_background":"classic","animation":"fadeIn"}'
        >
          <div className="elementor-background-overlay" />
          <div className="elementor-container elementor-column-gap-extended">
            <div
              className="elementor-column elementor-col-100 elementor-top-column elementor-element elementor-element-a9d86c7 sc_layouts_column_icons_position_left"
              data-id="a9d86c7"
              data-element_type="column"
            >
              <div className="elementor-widget-wrap elementor-element-populated">
                <div
                  className="sc_layouts_item elementor-element elementor-element-6021224 sc_fly_static elementor-widget elementor-widget-spacer"
                  data-id={6021224}
                  data-element_type="widget"
                  data-widget_type="spacer.default"
                >
                  <div className="elementor-widget-container">
                    <div className="elementor-spacer">
                      <div className="elementor-spacer-inner" />
                    </div>
                  </div>
                </div>
                <div
                  className="sc_layouts_item elementor-element elementor-element-0b423d8 scheme_dark elementor-widget__width-initial sc_fly_static elementor-widget elementor-widget-trx_sc_title"
                  data-id="0b423d8"
                  data-element_type="widget"
                  data-widget_type="trx_sc_title.default"
                >
                  <div className="elementor-widget-container">
                    <div
                      id="trx_sc_title_1224429932"
                      className="sc_title scheme_dark sc_title_default"
                    >
                      <span className="sc_item_subtitle sc_title_subtitle sc_align_center sc_item_subtitle_above sc_item_title_style_default">
                        MEME COIN FOR THE PEOPLE
                      </span>
                      <h1 className="sc_item_title sc_title_title sc_align_center sc_item_title_style_default sc_item_title_tag">
                        <span className="sc_item_title_text">
                          United We Meme <br />
                          <b>politicoin</b>
                        </span>
                      </h1>
                    </div>
                    {/* /.sc_title */}
                  </div>
                </div>
                <div
                  className="sc_layouts_item elementor-element elementor-element-23483a9 sc_fly_static elementor-widget elementor-widget-spacer"
                  data-id="23483a9"
                  data-element_type="widget"
                  data-widget_type="spacer.default"
                >
                  <div className="elementor-widget-container">
                    <div className="elementor-spacer">
                      <div className="elementor-spacer-inner" />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
        <section
          className="elementor-section elementor-top-section elementor-element elementor-element-f358874 elementor-section-boxed elementor-section-height-default elementor-section-height-default"
          data-id="f358874"
          data-element_type="section"
        >
          <div className="elementor-container elementor-column-gap-extended">
            <div
              className="elementor-column elementor-col-100 elementor-top-column elementor-element elementor-element-e2b920e sc_layouts_column_icons_position_left"
              data-id="e2b920e"
              data-element_type="column"
            >
              <div className="elementor-widget-wrap elementor-element-populated">
                <div
                  className="sc_layouts_item elementor-element elementor-element-e5a187f sc_fly_static elementor-widget elementor-widget-text-editor"
                  data-id="e5a187f"
                  data-element_type="widget"
                  data-widget_type="text-editor.default"
                >
                  <div className="elementor-widget-container" />
                </div>
              </div>
            </div>
          </div>
        </section>
      </div>
    </footer>
  );
}

export default Footer;
